<template>
  <div>
    <el-button icon="el-icon-plus" style="margin-bottom:12px" type="primary" size="small" @click="openDialog"
      >发布公告</el-button
    >
    <el-dialog
      :visible.sync="isShowBox"
      title="新增公告"
      width="60"
      :close-on-click-modal="false"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="公告标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="公告内容" prop="msg">
          <el-input
            type="textarea"
            :rows="3"
            v-model="form.msg"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleSubmit('ruleForm')">发布</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-table border :data="newsData" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="wrap">
            <el-tag size="small">发布人:{{ props.row.formName }}</el-tag>
            <p>{{ props.row.msg }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="序号" width="100" align="center">
      </el-table-column>
      <el-table-column prop="title" label="标题" min-width="450">
      </el-table-column>
      <el-table-column
        prop="formName"
        label="管理员"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope" width="auto">
          <el-button type="text" size="mini" @click="delNews(scope.row)">
            <span style="color: red">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { pushNews, getNews, delNews } from "../../api/news/index";
export default {
  name: "News",
  data() {
    return {
      form: {
        title: "",
        msg: "",
      },
      newsData: [],
      isShowBox: false,
      rules: {
          title: [
            { required: true, message: '请输入公告标题', trigger: 'blur' },
          ],
          msg: [
            { required: true, message: '请输入公告内容', trigger: 'change' }
          ],
      }
    };
  },
  methods: {
    handleSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.pushNews();
          } else {
            this.$message.error('请完整填写表单内容');
            return false;
          }
        });
      },
    pushNews() {
      pushNews(this.form.title, this.form.msg)
        .then(() => {
          this.$message.success("发布成功！");
          this.form.title === '';
          this.form.msg === '';
          this.isShowBox = false;
          this.initNews();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取news
    initNews() {
      getNews()
        .then((res) => {
          this.newsData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除公告
    delNews(row) {
      this.$confirm("是否确认删除该公告", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delNews(row.id)
          .then(() => {
            this.$message.success("删除成功！");
            this.initNews();
          })
          .catch(() => {
            console.log("err");
          });
      });
    },
    //新增公告
    openDialog() {
      this.isShowBox = true;
    },
  },
  mounted() {
    this.initNews();
  },
};
</script>

<style scoped lang="less">

#icon-show {
  margin-left: 20px;
  font-size: 16px;
}
.wrap {
  padding: 10px 30px;
  margin: 10px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  p {
    margin-top: 5px;
  }
}
</style>
